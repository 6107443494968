import * as React from 'react'
import Text, { Props as TextProps } from './Text'
import useAutoFocus from '../hooks/use-auto-focus'

type Props = TextProps & {
  autoFocus?: boolean
  nativeProps?: Record<string, any>
}

const Heading: React.FC<Props> = ({ autoFocus, nativeProps, ...props }) => {
  const ref = useAutoFocus(undefined, autoFocus)

  return (
    <Text
      fontFamily="inter"
      weight="bold"
      {...props}
      nativeProps={{
        ref,
        tabIndex: autoFocus ? -1 : undefined,
        ...nativeProps,
      }}
    />
  )
}

export default Heading
