import * as React from 'react'
import Stylo, { TestableProps } from './Stylo'
import { ThemeSchema, Weights } from '../themes/types'
import { CmpStyles, Style } from '../providers/style/types'

export type Props = TestableProps & {
  align?: 'left' | 'right' | 'center'
  fontFamily?: 'lato' | 'inter' | 'icon' | 'Roboto Mono'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'secondaryIcon'
    | 'placeholder'
    | 'error'
    | 'warning'
    | string
  letterSpacing?: string | number
  weight?: keyof Weights
  textDecoration?: 'none' | 'underline'
  'textDecoration:onHover'?: 'none' | 'underline'
  transform?: 'uppercase'
  variant?: 'small-caps'
  isItalic?: boolean
  selectable?: boolean
  scale?: number
  style?: Style
  showEllipsis?: boolean
  direction?: 'rtl' | 'ltr'
  children?: string | React.ReactNode
  [key: string]: any // accept any properties
}

export const getFontSize = (
  { fontFamily = 'lato', scale = 0 }: { fontFamily?: string; scale?: number },
  theme: ThemeSchema
): number =>
  // @ts-ignore
  theme.typo[fontFamily].fontSize[scale]

export const getLineHeight = (
  { fontFamily = 'lato', scale = 0 }: { fontFamily?: string; scale?: number },
  theme: ThemeSchema
): string =>
  // @ts-ignore
  theme.typo[fontFamily].lineHeight[scale]

export const getLetterSpacing = (
  { fontFamily = 'lato', scale = 0 }: { fontFamily?: string; scale?: number },
  theme: ThemeSchema
): string =>
  // @ts-ignore
  theme.typo[fontFamily].letterSpacing[scale]

export const styles: CmpStyles = {
  text: ({ theme, expand, ...props }) => {
    const {
      fontFamily = 'lato',
      weight,
      color = 'primary',
      isItalic,
      align,
      textDecoration,
      transform,
      direction,
      variant,
      selectable = true,
      'textDecoration:onHover': textDecorationOnHover,
    } = props as Props

    return {
      fontFamily,
      display: 'inline',
      fontStyle: isItalic ? 'italic' : 'normal',
      fontSize: getFontSize(props, theme),
      // @ts-ignore
      fontWeight: theme.typo[fontFamily].weight[weight] || weight,
      fontVariant: variant,
      lineHeight: getLineHeight(props, theme),
      letterSpacing: props.letterSpacing || getLetterSpacing(props, theme),
      textAlign: align,
      textDecoration,
      textTransform: transform,
      direction,
      userSelect: selectable ? undefined : 'none',
      ...(props.showEllipsis
        ? {
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }
        : null),
      color:
        // @ts-ignore
        color && theme.components.Text[color]
          ? // @ts-ignore
            theme.components.Text[color].color
          : color,
      ...(expand && { flex: 1 }),
      textUnderlineOffset: 2, // will anybody notice? just a test
      ':hover': {
        textDecoration: textDecorationOnHover,
      },
    }
  },
}

const Text: React.FC<Props> = React.forwardRef(
  ({ style, nativeRef, ...restProps }, ref) => (
    <Stylo
      as="span"
      {...restProps}
      nativeRef={ref || nativeRef}
      style={style ? [styles.text, style] : styles.text}
    />
  )
)

Text.defaultProps = {
  scale: 0,
  fontFamily: 'lato',
  weight: 'regular',
}

export default Text
